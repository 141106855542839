.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.leaflet-container {
  width: 100% !important;
  height: 90vh !important;
  z-index: 1;
}

.cursor {
  cursor: pointer;
}
.text-primary-app {

}


.chat-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.message-row {
  display: flex;
  margin-bottom: 10px;
}

.message-bubble {
  /* max-width: 70%; */
  padding: 8px 12px;
  border-radius: 10px;
  word-wrap: break-word;
}

.receiver {
  align-self: flex-start;
  background-color: #f0f0f0;
}

.sender {
  align-self: flex-end;
  background-color: #dcf8c6;
}
